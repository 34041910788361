@charset "utf-8";

/* roboto-condensed-regular - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  src: url('/layout/fonts/roboto-condensed-v16-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto Condensed'), local('RobotoCondensed-Regular'),
       url('/layout/fonts/roboto-condensed-v16-latin-regular%EF%B9%96.eot#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/layout/fonts/roboto-condensed-v16-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/layout/fonts/roboto-condensed-v16-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/layout/fonts/roboto-condensed-v16-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/layout/fonts/roboto-condensed-v16-latin-regular.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Slabo 27px';
  font-style: normal;
  font-weight: 400;
  src: url('/layout/fonts/slabo-27px-v4-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Slabo 27px'), local('Slabo27px-Regular'),
       url('/layout/fonts/slabo-27px-v4-latin-regular%EF%B9%96.eot#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/layout/fonts/slabo-27px-v4-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/layout/fonts/slabo-27px-v4-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/layout/fonts/slabo-27px-v4-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/layout/fonts/slabo-27px-v4-latin-regular.svg#Slabo27px') format('svg'); /* Legacy iOS */
}

/* ** ********************************************************** ** */
/* *** Defaultwerte *** */
/* ** ********************************************************** ** */
body,html {margin:0;padding:0;min-height:100%;overflow-x:hidden;}

p,h1,h2,h3,h4 {margin:0;padding:0;font-weight:normal;}

ul,li {margin:0;padding:0;list-style:none;}

a,
a:link,
a:visited,
a:active,
a:hover {text-decoration:none;color:#000;font-weight:normal;}

.content a {
	color: #53a7c0;
}
a[href*=".pdf"],
a.download {
	font-size:15px;
	padding:0 0 0 25px;
	background:url(../00_images/download.svg) no-repeat left center;
	background-size:15px 15px;
	line-height:30px;
}


table {border-collapse:collapse;margin:0;padding:0;}

input,
textarea,
select { 
	-webkit-box-sizing:border-box;-moz-box-sizing:border-box;-o-box-sizing:border-box;-ms-box-sizing:border-box;box-sizing:border-box;
	-webkit-border-radius:0;-moz-border-radius:0;-o-border-radius:0;-ms-border-radius:0;border-radius:0;
	margin:0;
}
/* ** ********************************************************** ** */
/* *** Allgemeine Klassen *** */
/* ** ********************************************************** ** */
.clear 			{clear:both;}

@media screen and (min-width:501px){
	.left 			{float:left;width:50%;}
	.right 			{float:right;width:50%;}
	.left p,
	.left h2,
	.left h3 		{padding-right:10px;}
	.right p,
	.right h2,
	.right h3 		{padding-left:10px;}
}

.align_right {text-align:right;}
.align_center {text-align:center;}

.full_width {width:100% !important;}

.spacer_small {height:10px;clear:both;}
.spacer_middle {height:20px;clear:both;}
.spacer_big {height:30px;clear:both;}

body,
html,
input,
textarea,
select {font-family:'Roboto Condensed', sans-serif;font-size:20px;}

label {display:inline-block;width:200px;font-size:15px;line-height:18px;}
input,
textarea,
select {border:1px solid #000;width:100%;font-size:15px;}
input[type="radio"],
input[type="checkbox"] {width:auto;}

p,
li,
td, th				{font-size:15px;line-height:18px;}
ul:not(.nav) li 					{
	padding:4px 0 0 20px;
	margin:0;
	background:url(../00_images/arrow_li.svg) no-repeat left 8px;
	background-size:12px 12px;
}
h1,h2,h3,span.highlight {
	font-size:25px;
	line-height:28px;
	text-transform:uppercase;
	font-family:'Slabo 27px', serif;
}
h1 {
	font-size:38px;
	line-height:33px;}
h3,span.highlight 	{
	font-size:18px;
	line-height:18px;
	margin-bottom: 0;
}

p.absatz 			{padding-bottom:5px;}

/* ** ********************************************************** ** */
/* *** Basis Elemente *** */
/* ** ********************************************************** ** */
body 					{
	position:relative;
	z-index:1;
	// overflow-x:hidden;
	width:100%;
	@media screen and (max-width: 500px) {
		background: linear-gradient(to right, #dbebef 0%, #dbebef 12%, #ffffff 28%, #b7d9e2 72%, #b7d9e2 100%);
	}

	.background {
		font-size: 0;
		margin-bottom: 30px;
		@media screen and (min-width: 501px) {
			margin-bottom: 0;
			position:fixed;
			top:0;left:0;
			width:100%;height:100%;
			z-index:-3;
			overflow:hidden;
			background-repeat:no-repeat;
			background-position:center center;
			background-size:cover;
			background: linear-gradient(to right, #dbebef 0%, #dbebef 12%, #ffffff 28%, #b7d9e2 72%, #b7d9e2 100%);
		}
		&.no_gradient  {
			background: #c9e5eb;
		}
		img {
			width: 100%;
    	height: auto;
			object-fit: cover;
			object-position: 50% 50%;
		}
		@media screen and (min-width: 501px) {
			img {
				height: 100%;
			}
		}

		&.is_arzt img {
			object-position: 80% 30%;
			@media screen and (max-width: 1200px) {
				object-position: 70% 30%;
			}
		}

	}
}





section 						{position:relative;z-index:30;}
header.wrapper 					{position:relative;z-index:100;width:76%;padding:35px 8% 0 16%;}
section.wrapper 				{position:relative;z-index:40;width:76%;padding:65px 8% 65px 16%;}
section.wrapper .content		{
	position:relative;
	z-index:50;
	max-width: 860px;
	min-height: 400px;
}
section.width_1.wrapper .content{max-width:300px;}
section.width_2.wrapper .content{max-width:500px;}
section.width_3.wrapper .content{max-width:750px;}
section.width_4.wrapper .content{max-width:860px;}

header a.logo 			{width:280px;height:67px;line-height:0;margin:0 0 0 -77px;padding:0 0 20px 0;display:block;}
header a.logo img		{width:100%;height:100%;line-height:0;display:block;}
header nav 				{height:160px;position:relative;z-index:150;max-width:250px}
header nav a 			{display:block;font-size:17px;text-transform:uppercase;line-height:18px;}
header nav a.active		{font-size:30px;line-height:35px;}
header nav li:first-child {	
	display: none;
}

.button {
	@media screen and (min-width: 501px) {
		width:240px;

	}
	a,
	a:link,
	a:visited,
	a:active,
	a:hover 	{

		padding-top:20px;
		padding-bottom:40px;
		text-transform:uppercase;
		font-size:17px;
		color:#53A7C0;
		display:block;
	}
	a.weiter 	{
		text-align:right;
		float:right;}
	a.zurueck 	{
		float:left;}
}

.arzt.width_2 .button.prom a:before {content:'» ';}

/* ** ********************************************************** ** */
/* *** Startseite *** */
/* ** ********************************************************** ** */
.startseite p 					{font-size:18px;line-height:22px;}
.startseite span.highlight,
.startseite h3 	{font-size:22px;line-height:22px;}
.startseite h1,
.startseite h2 					{font-size:38px;line-height:33px;}
.startseite h2 					{padding:25px 0 0 0;}

/* ** ********************************************************** ** */
/* *** Ärzte *** */
/* ** ********************************************************** ** */
.arztgruppe {
	position:absolute;
	bottom:0; left:0;
	width:270px;
	height:200px;
	background: url(../00_images/arztgruppe.png) left top no-repeat;
	background-size:100% auto;
	z-index:40;
	@media screen and (max-width: 500px) {
		display: none;
	}
}


/* ** ********************************************************** ** */
/* *** Kontakt *** */
/* ** ********************************************************** ** */
.kontakt table.offen 							{

}
table {
	td {
		padding-right: .4em;
	}
}
/* ** ********************************************************** ** */
/* *** durchsichtige Kreise *** */
/* ** ********************************************************** ** */
.lens 					{background:#fff;position:absolute;top:0;left:0;z-index:-2;}

.lenses 				{position:absolute;top:28px;left:0;width:100%;
	z-index:50;height:75px;}
.lenses .lens 			{width:75px;height:75px;border-radius:50px;opacity:0.3;}
.lenses .lens.lens1		{left:-3%;}
.lenses .lens.lens2		{left:0%;}
.lenses .lens.lens3		{left:8%;/*opacity:0.7;*/}
.lenses .lens.lens4		{left:9.4%;/*opacity:0.7;*/}
.lenses .lens.lens5		{left:32%;}
.lenses .lens.lens6		{left:35%;}
.lenses .lens.lens7		{left:45%;}
.lenses .lens.lens8		{left:83%;}
.lenses .lens.lens9		{left:86%;}
.lenses .lens.lens10	{left:95%;}
.lenses .lens.lens11	{left:98%;}

.hg_lenses 				{position:absolute;top:-65px;left:0;width:100%;z-index:-2;height:420px;}
.hg_lenses .lens 		{width:420px;height:420px;border-radius:500px;opacity:0.5;}

.hg_lenses.lenses-2 .lens.lens1	{left:-320px;opacity:0.6;}
.hg_lenses.lenses-2 .lens.lens2	{left:-75px;opacity:0.9;}

.hg_lenses.lenses-3 .lens.lens3	{left:190px;opacity:0.9;}

.hg_lenses.lenses-5 .lens.lens1	{left:-410px;}
.hg_lenses.lenses-5 .lens.lens2	{left:-205px;}
.hg_lenses.lenses-5 .lens.lens3	{left:-35px;}
.hg_lenses.lenses-5 .lens.lens4	{left:170px;}
.hg_lenses.lenses-5 .lens.lens5	{left:420px;}

.hg_lenses.lenses-6 .lens.lens1	{left:-410px;}
.hg_lenses.lenses-6 .lens.lens2	{left:-205px;}
.hg_lenses.lenses-6 .lens.lens3	{left:-85px;}
.hg_lenses.lenses-6 .lens.lens4	{left:120px;}
.hg_lenses.lenses-6 .lens.lens5	{left:420px;}
.hg_lenses.lenses-6 .lens.lens6	{left:600px;}

.praxis_04 .hg_lenses .lens,
.praxis_06 .hg_lenses .lens {opacity:0.5 !important;}
.kontakt .hg_lenses .lens {opacity:0.7 !important;}

// .datenschutz p {line-height:21px;padding:0 0 10px 0;}

/* ** ********************************************************** ** */
/* *** Viewport spezifisch *** */
/* ** ********************************************************** ** */
@media screen and (max-width:1024px){
	header.wrapper 					{width:86%;padding-right:4%;padding-left:10%;}
	section.wrapper 				{width:86%;padding-right:4%;padding-left:10%;}
	section.wrapper .content		{}
	section.width_4.wrapper 		{height:auto;min-height:400px;padding-bottom:40px;}
	
	.kontaktmap .content 			{float:none;}

	.kontaktmap .map 				{float:none;width:100%;margin:0;max-width:inherit;padding:0;/*background:rgba(255,255,255,0.7);*/}
} 

@media screen and (max-width:800px){
	header.wrapper 					{width:auto;padding-right:0;padding-left:80px;}
	section.wrapper 				{width:92%;padding-left:4%;}
	

	
	.hg_lenses.lenses-6 .lens		{width:600px;height:600px;opacity:0.6;}
	.hg_lenses.lenses-6 .lens.lens1,
	.hg_lenses.lenses-6 .lens.lens3,
	.hg_lenses.lenses-6 .lens.lens5	{display:none;}
	
} 

@media screen and (max-width:500px){

body 							{
	background: #dbebef;
	background: linear-gradient(to right, #dbebef 0%, #dbebef 12%, #ffffff 28%, #b7d9e2 72%, #b7d9e2 100%);
}
	


.right 			{padding-top:20px;}

section.wrapper .content 	{height:auto;min-height:auto;max-width:inherit !important;width:92% !important;padding-left:4%;padding-right:4%;}

section.wrapper 							{background:none;padding:0;width:100%;}
body.noimage.fallback section.wrapper 		{background:rgba(255,255,255,0.7);}


.hg_lenses 					{display:none;}

header.wrapper .fadeout 	{cursor:pointer;position:absolute;top:40px;right:20px;background:url(../00_images/menu.svg) no-repeat center center;background-size:40px 40px;width:50px;height:50px;}
header.wrapper nav 			{display:none;}
header.wrapper.go nav 			{display:block;}

.kontaktmap .map 			{width:92%;padding:0 4% 40px 4%;}
}

