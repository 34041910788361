
.hinweis {
  max-width: 860px;
}
.hinweis h1 {
  font-size: 32px;
}
.hinweis p {
  font-size: 28px;
  line-height: 1.2;
  /* line-height: 33px; */
}
@media(max-width: 767px) {
  .hinweis p {
    font-size: 20px;
  }
  .hidden-onmobile {
    display: none;
  }
}

.startseite .background {
  background: #c9e5eb ;
}

h1, 
h2{
  // margin-bottom: .8em;
  margin-bottom: 1rem;
  .hinweis & {
    margin-bottom: 1.5rem;
  }
}
h3 {
  // margin-bottom: .8em;
  margin-bottom: .7rem;
}
p  {
  margin-bottom: 1rem;
  .hinweis & {
    margin-bottom: 1.5rem;
  }
}


.mb0 {
  margin-bottom: 0;
}


a[href$=".pdf"] {
  /* PDF file */
}

pre {
  font-size: 14px;
  line-height: 1em;
}