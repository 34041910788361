@charset "utf-8";

.col_row {margin-right:-40px;}
.col {float:left;}
.col .col_content {margin-right:40px;}

.show_l,
.show_m,
.show_s {display:none;}

/* Desktop */
@media screen and (min-width: 900px) {
.col_l_12 {width:100%;float:none;clear:both;}
.col_l_11 {width:91.6666666%;}
.col_l_10 {width:83.3333333%;}
.col_l_9 {width:75%;}
.col_l_8 {width:66.6666666%;}
.col_l_7 {width:58.3333333%;}
.col_l_6 {width:50%;}
.col_l_5 {width:41.6666666%;}
.col_l_4 {width:33%;}
.col_l_3 {width:25%;}
.col_l_2 {width:16.6666666%;}
.col_l_1 {width:8.33333333%;}

.clear_l {clear:both;}

.show_l {display:block;}
}

/* Tablet */
@media screen and (min-width: 400px) and (max-width: 900px) {
.col_m_12 {width:100%;float:none;clear:both;}
.col_m_11 {width:91.6666666%;}
.col_m_10 {width:83.3333333%;}
.col_m_9 {width:75%;}
.col_m_8 {width:66.6666666%;}
.col_m_7 {width:58.3333333%;}
.col_m_6 {width:50%;}
.col_m_5 {width:41.6666666%;}
.col_m_4 {width:33%;}
.col_m_3 {width:25%;}
.col_m_2 {width:16.6666666%;}
.col_m_1 {width:8.33333333%;}

.clear_m {clear:both;}

.show_m {display:block;}
}

/* Smartphone */
@media screen and (max-width: 400px) {
.col_row {margin-right:-10px !important;}
.col .col_content {margin-right:10px !important;}

.col_s_12 {width:100%;float:none;clear:both;}
.col_s_11 {width:91.6666666%;}
.col_s_10 {width:83.3333333%;}
.col_s_9 {width:75%;}
.col_s_8 {width:66.6666666%;}
.col_s_7 {width:58.3333333%;}
.col_s_6 {width:50%;}
.col_s_5 {width:41.6666666%;}
.col_s_4 {width:33%;}
.col_s_3 {width:25%;}
.col_s_2 {width:16.6666666%;}
.col_s_1 {width:8.33333333%;}

.clear_s {clear:both;}

.show_s {display:block;}
}