.ff-form-success {
  color: green;
}



/* layout */
form .freeform-row {
  @media screen and (min-width: 501px) {
    .freeform-column {
      display: flex;
      flex-wrap: wrap;
      .freeform-label {
        flex: 0 0 150px;
      }
      .freeform-input {
        flex: 1;
      }
      .ff-errors {
        flex: 0 0 100%;
        margin-left: 155px ;
      }
    }

  }

  input, 
  select, 
  textarea {
    border: 1px solid grey;
  }
  
  

  button {
    padding: 6px 10px;
    border: 0;
    color: white;
    background-color: #53a7c0;
    &:hover {
      cursor: pointer;
    }

    @media screen and (min-width: 501px) {
      margin-left: 150px;
    }
  }


  .freeform-column .freeform-label.freeform-required:after {
    color: black;
  }
}