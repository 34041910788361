@charset "UTF-8";
/* roboto-condensed-regular - latin */
@font-face {
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 400;
  src: url("/layout/fonts/roboto-condensed-v16-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Condensed"), local("RobotoCondensed-Regular"), url("/layout/fonts/roboto-condensed-v16-latin-regular%EF%B9%96.eot#iefix") format("embedded-opentype"), url("/layout/fonts/roboto-condensed-v16-latin-regular.woff2") format("woff2"), url("/layout/fonts/roboto-condensed-v16-latin-regular.woff") format("woff"), url("/layout/fonts/roboto-condensed-v16-latin-regular.ttf") format("truetype"), url("/layout/fonts/roboto-condensed-v16-latin-regular.svg#RobotoCondensed") format("svg");
  /* Legacy iOS */
}
@font-face {
  font-family: "Slabo 27px";
  font-style: normal;
  font-weight: 400;
  src: url("/layout/fonts/slabo-27px-v4-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Slabo 27px"), local("Slabo27px-Regular"), url("/layout/fonts/slabo-27px-v4-latin-regular%EF%B9%96.eot#iefix") format("embedded-opentype"), url("/layout/fonts/slabo-27px-v4-latin-regular.woff2") format("woff2"), url("/layout/fonts/slabo-27px-v4-latin-regular.woff") format("woff"), url("/layout/fonts/slabo-27px-v4-latin-regular.ttf") format("truetype"), url("/layout/fonts/slabo-27px-v4-latin-regular.svg#Slabo27px") format("svg");
  /* Legacy iOS */
}
/* ** ********************************************************** ** */
/* *** Defaultwerte *** */
/* ** ********************************************************** ** */
body, html {
  margin: 0;
  padding: 0;
  min-height: 100%;
  overflow-x: hidden;
}

p, h1, h2, h3, h4 {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}

a,
a:link,
a:visited,
a:active,
a:hover {
  text-decoration: none;
  color: #000;
  font-weight: normal;
}

.content a {
  color: #53a7c0;
}

a[href*=".pdf"],
a.download {
  font-size: 15px;
  padding: 0 0 0 25px;
  background: url(../00_images/download.svg) no-repeat left center;
  background-size: 15px 15px;
  line-height: 30px;
}

table {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
}

input,
textarea,
select {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -o-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  margin: 0;
}

/* ** ********************************************************** ** */
/* *** Allgemeine Klassen *** */
/* ** ********************************************************** ** */
.clear {
  clear: both;
}

@media screen and (min-width: 501px) {
  .left {
    float: left;
    width: 50%;
  }

  .right {
    float: right;
    width: 50%;
  }

  .left p,
.left h2,
.left h3 {
    padding-right: 10px;
  }

  .right p,
.right h2,
.right h3 {
    padding-left: 10px;
  }
}
.align_right {
  text-align: right;
}

.align_center {
  text-align: center;
}

.full_width {
  width: 100% !important;
}

.spacer_small {
  height: 10px;
  clear: both;
}

.spacer_middle {
  height: 20px;
  clear: both;
}

.spacer_big {
  height: 30px;
  clear: both;
}

body,
html,
input,
textarea,
select {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 20px;
}

label {
  display: inline-block;
  width: 200px;
  font-size: 15px;
  line-height: 18px;
}

input,
textarea,
select {
  border: 1px solid #000;
  width: 100%;
  font-size: 15px;
}

input[type=radio],
input[type=checkbox] {
  width: auto;
}

p,
li,
td, th {
  font-size: 15px;
  line-height: 18px;
}

ul:not(.nav) li {
  padding: 4px 0 0 20px;
  margin: 0;
  background: url(../00_images/arrow_li.svg) no-repeat left 8px;
  background-size: 12px 12px;
}

h1, h2, h3, span.highlight {
  font-size: 25px;
  line-height: 28px;
  text-transform: uppercase;
  font-family: "Slabo 27px", serif;
}

h1 {
  font-size: 38px;
  line-height: 33px;
}

h3, span.highlight {
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 0;
}

p.absatz {
  padding-bottom: 5px;
}

/* ** ********************************************************** ** */
/* *** Basis Elemente *** */
/* ** ********************************************************** ** */
body {
  position: relative;
  z-index: 1;
  width: 100%;
}
@media screen and (max-width: 500px) {
  body {
    background: linear-gradient(to right, #dbebef 0%, #dbebef 12%, #ffffff 28%, #b7d9e2 72%, #b7d9e2 100%);
  }
}
body .background {
  font-size: 0;
  margin-bottom: 30px;
}
@media screen and (min-width: 501px) {
  body .background {
    margin-bottom: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -3;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background: linear-gradient(to right, #dbebef 0%, #dbebef 12%, #ffffff 28%, #b7d9e2 72%, #b7d9e2 100%);
  }
}
body .background.no_gradient {
  background: #c9e5eb;
}
body .background img {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: 50% 50%;
}
@media screen and (min-width: 501px) {
  body .background img {
    height: 100%;
  }
}
body .background.is_arzt img {
  object-position: 80% 30%;
}
@media screen and (max-width: 1200px) {
  body .background.is_arzt img {
    object-position: 70% 30%;
  }
}

section {
  position: relative;
  z-index: 30;
}

header.wrapper {
  position: relative;
  z-index: 100;
  width: 76%;
  padding: 35px 8% 0 16%;
}

section.wrapper {
  position: relative;
  z-index: 40;
  width: 76%;
  padding: 65px 8% 65px 16%;
}

section.wrapper .content {
  position: relative;
  z-index: 50;
  max-width: 860px;
  min-height: 400px;
}

section.width_1.wrapper .content {
  max-width: 300px;
}

section.width_2.wrapper .content {
  max-width: 500px;
}

section.width_3.wrapper .content {
  max-width: 750px;
}

section.width_4.wrapper .content {
  max-width: 860px;
}

header a.logo {
  width: 280px;
  height: 67px;
  line-height: 0;
  margin: 0 0 0 -77px;
  padding: 0 0 20px 0;
  display: block;
}

header a.logo img {
  width: 100%;
  height: 100%;
  line-height: 0;
  display: block;
}

header nav {
  height: 160px;
  position: relative;
  z-index: 150;
  max-width: 250px;
}

header nav a {
  display: block;
  font-size: 17px;
  text-transform: uppercase;
  line-height: 18px;
}

header nav a.active {
  font-size: 30px;
  line-height: 35px;
}

header nav li:first-child {
  display: none;
}

@media screen and (min-width: 501px) {
  .button {
    width: 240px;
  }
}
.button a,
.button a:link,
.button a:visited,
.button a:active,
.button a:hover {
  padding-top: 20px;
  padding-bottom: 40px;
  text-transform: uppercase;
  font-size: 17px;
  color: #53A7C0;
  display: block;
}
.button a.weiter {
  text-align: right;
  float: right;
}
.button a.zurueck {
  float: left;
}

.arzt.width_2 .button.prom a:before {
  content: "» ";
}

/* ** ********************************************************** ** */
/* *** Startseite *** */
/* ** ********************************************************** ** */
.startseite p {
  font-size: 18px;
  line-height: 22px;
}

.startseite span.highlight,
.startseite h3 {
  font-size: 22px;
  line-height: 22px;
}

.startseite h1,
.startseite h2 {
  font-size: 38px;
  line-height: 33px;
}

.startseite h2 {
  padding: 25px 0 0 0;
}

/* ** ********************************************************** ** */
/* *** Ärzte *** */
/* ** ********************************************************** ** */
.arztgruppe {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 270px;
  height: 200px;
  background: url(../00_images/arztgruppe.png) left top no-repeat;
  background-size: 100% auto;
  z-index: 40;
}
@media screen and (max-width: 500px) {
  .arztgruppe {
    display: none;
  }
}

/* ** ********************************************************** ** */
/* *** Kontakt *** */
/* ** ********************************************************** ** */
table td {
  padding-right: 0.4em;
}

/* ** ********************************************************** ** */
/* *** durchsichtige Kreise *** */
/* ** ********************************************************** ** */
.lens {
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
}

.lenses {
  position: absolute;
  top: 28px;
  left: 0;
  width: 100%;
  z-index: 50;
  height: 75px;
}

.lenses .lens {
  width: 75px;
  height: 75px;
  border-radius: 50px;
  opacity: 0.3;
}

.lenses .lens.lens1 {
  left: -3%;
}

.lenses .lens.lens2 {
  left: 0%;
}

.lenses .lens.lens3 {
  left: 8%;
  /*opacity:0.7;*/
}

.lenses .lens.lens4 {
  left: 9.4%;
  /*opacity:0.7;*/
}

.lenses .lens.lens5 {
  left: 32%;
}

.lenses .lens.lens6 {
  left: 35%;
}

.lenses .lens.lens7 {
  left: 45%;
}

.lenses .lens.lens8 {
  left: 83%;
}

.lenses .lens.lens9 {
  left: 86%;
}

.lenses .lens.lens10 {
  left: 95%;
}

.lenses .lens.lens11 {
  left: 98%;
}

.hg_lenses {
  position: absolute;
  top: -65px;
  left: 0;
  width: 100%;
  z-index: -2;
  height: 420px;
}

.hg_lenses .lens {
  width: 420px;
  height: 420px;
  border-radius: 500px;
  opacity: 0.5;
}

.hg_lenses.lenses-2 .lens.lens1 {
  left: -320px;
  opacity: 0.6;
}

.hg_lenses.lenses-2 .lens.lens2 {
  left: -75px;
  opacity: 0.9;
}

.hg_lenses.lenses-3 .lens.lens3 {
  left: 190px;
  opacity: 0.9;
}

.hg_lenses.lenses-5 .lens.lens1 {
  left: -410px;
}

.hg_lenses.lenses-5 .lens.lens2 {
  left: -205px;
}

.hg_lenses.lenses-5 .lens.lens3 {
  left: -35px;
}

.hg_lenses.lenses-5 .lens.lens4 {
  left: 170px;
}

.hg_lenses.lenses-5 .lens.lens5 {
  left: 420px;
}

.hg_lenses.lenses-6 .lens.lens1 {
  left: -410px;
}

.hg_lenses.lenses-6 .lens.lens2 {
  left: -205px;
}

.hg_lenses.lenses-6 .lens.lens3 {
  left: -85px;
}

.hg_lenses.lenses-6 .lens.lens4 {
  left: 120px;
}

.hg_lenses.lenses-6 .lens.lens5 {
  left: 420px;
}

.hg_lenses.lenses-6 .lens.lens6 {
  left: 600px;
}

.praxis_04 .hg_lenses .lens,
.praxis_06 .hg_lenses .lens {
  opacity: 0.5 !important;
}

.kontakt .hg_lenses .lens {
  opacity: 0.7 !important;
}

/* ** ********************************************************** ** */
/* *** Viewport spezifisch *** */
/* ** ********************************************************** ** */
@media screen and (max-width: 1024px) {
  header.wrapper {
    width: 86%;
    padding-right: 4%;
    padding-left: 10%;
  }

  section.wrapper {
    width: 86%;
    padding-right: 4%;
    padding-left: 10%;
  }

  section.width_4.wrapper {
    height: auto;
    min-height: 400px;
    padding-bottom: 40px;
  }

  .kontaktmap .content {
    float: none;
  }

  .kontaktmap .map {
    float: none;
    width: 100%;
    margin: 0;
    max-width: inherit;
    padding: 0;
    /*background:rgba(255,255,255,0.7);*/
  }
}
@media screen and (max-width: 800px) {
  header.wrapper {
    width: auto;
    padding-right: 0;
    padding-left: 80px;
  }

  section.wrapper {
    width: 92%;
    padding-left: 4%;
  }

  .hg_lenses.lenses-6 .lens {
    width: 600px;
    height: 600px;
    opacity: 0.6;
  }

  .hg_lenses.lenses-6 .lens.lens1,
.hg_lenses.lenses-6 .lens.lens3,
.hg_lenses.lenses-6 .lens.lens5 {
    display: none;
  }
}
@media screen and (max-width: 500px) {
  body {
    background: #dbebef;
    background: linear-gradient(to right, #dbebef 0%, #dbebef 12%, #ffffff 28%, #b7d9e2 72%, #b7d9e2 100%);
  }

  .right {
    padding-top: 20px;
  }

  section.wrapper .content {
    height: auto;
    min-height: auto;
    max-width: inherit !important;
    width: 92% !important;
    padding-left: 4%;
    padding-right: 4%;
  }

  section.wrapper {
    background: none;
    padding: 0;
    width: 100%;
  }

  body.noimage.fallback section.wrapper {
    background: rgba(255, 255, 255, 0.7);
  }

  .hg_lenses {
    display: none;
  }

  header.wrapper .fadeout {
    cursor: pointer;
    position: absolute;
    top: 40px;
    right: 20px;
    background: url(../00_images/menu.svg) no-repeat center center;
    background-size: 40px 40px;
    width: 50px;
    height: 50px;
  }

  header.wrapper nav {
    display: none;
  }

  header.wrapper.go nav {
    display: block;
  }

  .kontaktmap .map {
    width: 92%;
    padding: 0 4% 40px 4%;
  }
}
.col_row {
  margin-right: -40px;
}

.col {
  float: left;
}

.col .col_content {
  margin-right: 40px;
}

.show_l,
.show_m,
.show_s {
  display: none;
}

/* Desktop */
@media screen and (min-width: 900px) {
  .col_l_12 {
    width: 100%;
    float: none;
    clear: both;
  }

  .col_l_11 {
    width: 91.6666666%;
  }

  .col_l_10 {
    width: 83.3333333%;
  }

  .col_l_9 {
    width: 75%;
  }

  .col_l_8 {
    width: 66.6666666%;
  }

  .col_l_7 {
    width: 58.3333333%;
  }

  .col_l_6 {
    width: 50%;
  }

  .col_l_5 {
    width: 41.6666666%;
  }

  .col_l_4 {
    width: 33%;
  }

  .col_l_3 {
    width: 25%;
  }

  .col_l_2 {
    width: 16.6666666%;
  }

  .col_l_1 {
    width: 8.33333333%;
  }

  .clear_l {
    clear: both;
  }

  .show_l {
    display: block;
  }
}
/* Tablet */
@media screen and (min-width: 400px) and (max-width: 900px) {
  .col_m_12 {
    width: 100%;
    float: none;
    clear: both;
  }

  .col_m_11 {
    width: 91.6666666%;
  }

  .col_m_10 {
    width: 83.3333333%;
  }

  .col_m_9 {
    width: 75%;
  }

  .col_m_8 {
    width: 66.6666666%;
  }

  .col_m_7 {
    width: 58.3333333%;
  }

  .col_m_6 {
    width: 50%;
  }

  .col_m_5 {
    width: 41.6666666%;
  }

  .col_m_4 {
    width: 33%;
  }

  .col_m_3 {
    width: 25%;
  }

  .col_m_2 {
    width: 16.6666666%;
  }

  .col_m_1 {
    width: 8.33333333%;
  }

  .clear_m {
    clear: both;
  }

  .show_m {
    display: block;
  }
}
/* Smartphone */
@media screen and (max-width: 400px) {
  .col_row {
    margin-right: -10px !important;
  }

  .col .col_content {
    margin-right: 10px !important;
  }

  .col_s_12 {
    width: 100%;
    float: none;
    clear: both;
  }

  .col_s_11 {
    width: 91.6666666%;
  }

  .col_s_10 {
    width: 83.3333333%;
  }

  .col_s_9 {
    width: 75%;
  }

  .col_s_8 {
    width: 66.6666666%;
  }

  .col_s_7 {
    width: 58.3333333%;
  }

  .col_s_6 {
    width: 50%;
  }

  .col_s_5 {
    width: 41.6666666%;
  }

  .col_s_4 {
    width: 33%;
  }

  .col_s_3 {
    width: 25%;
  }

  .col_s_2 {
    width: 16.6666666%;
  }

  .col_s_1 {
    width: 8.33333333%;
  }

  .clear_s {
    clear: both;
  }

  .show_s {
    display: block;
  }
}
.hinweis {
  max-width: 860px;
}

.hinweis h1 {
  font-size: 32px;
}

.hinweis p {
  font-size: 28px;
  line-height: 1.2;
  /* line-height: 33px; */
}

@media (max-width: 767px) {
  .hinweis p {
    font-size: 20px;
  }

  .hidden-onmobile {
    display: none;
  }
}
.startseite .background {
  background: #c9e5eb;
}

h1,
h2 {
  margin-bottom: 1rem;
}
.hinweis h1,
.hinweis h2 {
  margin-bottom: 1.5rem;
}

h3 {
  margin-bottom: 0.7rem;
}

p {
  margin-bottom: 1rem;
}
.hinweis p {
  margin-bottom: 1.5rem;
}

.mb0 {
  margin-bottom: 0;
}

a[href$=".pdf"] {
  /* PDF file */
}

pre {
  font-size: 14px;
  line-height: 1em;
}

.ff-form-success {
  color: green;
}

/* layout */
@media screen and (min-width: 501px) {
  form .freeform-row .freeform-column {
    display: flex;
    flex-wrap: wrap;
  }
  form .freeform-row .freeform-column .freeform-label {
    flex: 0 0 150px;
  }
  form .freeform-row .freeform-column .freeform-input {
    flex: 1;
  }
  form .freeform-row .freeform-column .ff-errors {
    flex: 0 0 100%;
    margin-left: 155px;
  }
}
form .freeform-row input,
form .freeform-row select,
form .freeform-row textarea {
  border: 1px solid grey;
}
form .freeform-row button {
  padding: 6px 10px;
  border: 0;
  color: white;
  background-color: #53a7c0;
}
form .freeform-row button:hover {
  cursor: pointer;
}
@media screen and (min-width: 501px) {
  form .freeform-row button {
    margin-left: 150px;
  }
}
form .freeform-row .freeform-column .freeform-label.freeform-required:after {
  color: black;
}